<template>
  <page-main
    title="What about your career and personal interests?"
    :aside="[
      'Where do you want your career to go from here?',
      'What are you passionate or interested in, outside of your career?'
    ]"
    nextText="Now on to your skills!"
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/HardSkills')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Career Interests"
            titleText="Select up to 5 areas of Career interest:"
            :optionsList="baseCareerInterests"
            :selected.sync="careerInterests"
            tabindex="1"
            autofocus
          ></r-multi-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Personal interests"
            titleText="List your Personal interests:"
            :optionsList="[]"
            :selected.sync="personalInterests"
            tabindex="2"
          ></r-multi-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as Display from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "CareerInterest",
  data: () => ({}),
  components: {},
  methods: {
    save() {
      this.findDisplayValues();
      this.saveCareerInterests();
      this.savePersonalInterests();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("careerInterest", ["saveCareerInterests"]),
    ...mapActions("personalInterest", ["savePersonalInterests"]),
    findDisplayValues() {
      let careerValue = Display.BuildCommaString("", this.careerInterests);

      let personalInterestValue = Display.BuildCommaString(
        "",
        this.personalInterests
      );

      this.careerInterestsDisplay = careerValue;
      this.personalInterestsDisplay = personalInterestValue;

      return [careerValue, personalInterestValue];
    }
  },
  mounted() {},
  computed: {
    ...mapFields("careerInterest", [
      "careerInterests",
      "careerInterestsDisplay",
      "baseCareerInterests"
    ]),
    ...mapFields("personalInterest", [
      "personalInterests",
      "personalInterestsDisplay"
    ]),
    displayValues: function() {
      return this.findDisplayValues();
    }
  }
};
</script>
